import Vue from "vue";
import VueRouter from "vue-router";
// import CommonStore from "@/store/index.js";

// import { clearCache } from "@/utils/app";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index"
  },

  {
    path: "/pdf",
    name: "Pdf",
    meta: {},
    component: () => import("@/views/pdf/Pdf.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// 没有真实登录,即接口没有token校验
// 这里后端让前端用expired来判断过期时间
// whiteList 这个是用来存放不需要进行expired校验，即没有走登录接口也可以访问的路由
// 由于这个whiteList是徐玉舒设计的，为了保证代码的稳定性，所以这里不作修改
// const whiteList = ["/login", "/home", "/success", "/identity", "/pdf"];
// const isDev = process.env.NODE_ENV === "development";

// import store from "@/store";
// import { getToken } from "@/utils/app";
// import { Toast } from "vant";

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "H5";
  }
  next();
});

export default router;
