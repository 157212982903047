<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { tologin } from "./utils/app";

export default {
  methods: {
    clear() {
      window.sessionStorage.clear();
      window.localStorage.clear();
    }
  },
  mounted() {
    // 监听 401 事件
    window.addEventListener(
      "AppLoginEvent",
      () => {
        if (this.$route.path !== "/login") {
          tologin();
        }
      },
      false
    );

    // 监听 403 事件
    window.addEventListener(
      "AppForbiddenEvent",
      (e) => {
        if (this.$route.path !== "/login") {
          tologin();
        }
      },
      false
    );
  }
};
</script>
<style lang="scss">
@import "./styles/app.scss";
</style>
