import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import filters from "@/utils/filter";
import store from "./store";
import "lib-flexible/flexible";
import VConsole from "vconsole";

Vue.use(filters);
// 可以在调试界面输入指令打开vconsole
window.checkOpen = () => {
  localStorage.setItem("sfxh_cooperator_h5_vconsole_key", "open");
  location.reload();
};
// 可以在调试界面输入指令关闭vconsole
window.checkClose = () => {
  localStorage.setItem("sfxh_cooperator_h5_vconsole_key", "close");
  location.reload();
};
const open = localStorage.getItem("sfxh_cooperator_h5_vconsole_key") == "open";
if (open || process.env.NODE_ENV == "development") new VConsole();

// 测试时可放开
// new VConsole();

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
import { Toast, Dialog, Notify, ImagePreview } from "vant";

Vue.prototype.$toast = Toast;
Vue.prototype.$Dialog = Dialog;
Vue.prototype.$notify = Notify;
Vue.prototype.$ImagePreview = ImagePreview;

Vue.prototype.$errMsg = (error) => {
  console.log("error", error);
  Toast.clear(true);
  return Toast.fail(error.message || error.msg || error || "未知错误");
};
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
